import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"box",attrs:{"width":"800"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.show = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VListItem,_vm._g({staticClass:"link",attrs:{"selectable":""}},on),[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VListItemTitle,{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-3 px-6 align-center sidebar"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary mr-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c(VCardText,{staticClass:"pt-7"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.hex)?_c('HexView'):_c('ValueInspector',{attrs:{"prim":"string","value":_vm.text,"network":_vm.network,"label":_vm.title}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }