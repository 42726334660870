import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,[_c(VListItem,{staticStyle:{"height":"74px"}},[_c(VListItemContent,{attrs:{"two-line":""}},[_c(VListItemTitle,{staticClass:"headline"},[_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.alias && _vm.alias.length < 25},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"inherit"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.alias ? _vm.alias : _vm.address))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.alias ? _vm.alias : _vm.address))])])],1),_c(VListItemSubtitle,[_c('span',{staticClass:"overline",class:_vm.network === 'mainnet' ? 'secondary--text' : ''},[_vm._v(_vm._s(_vm.network))])])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Was active")]),_c(VListItemTitle,{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.helpers.formatDatetime(_vm.contract.last_action))+" ")])],1)],1),(_vm.balance)?_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Balance")]),_c(VListItemTitle,{staticClass:"body-2"},[_c('span',[_vm._v(_vm._s(_vm._f("umav")(_vm.balance)))])])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }